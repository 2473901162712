.image-container {
    position: relative;
    width: 100%;  /* Adjust the width as needed */
    overflow: hidden; /* Hide any overflow */
}

.image-container img {
    width: 100%;  /* Make the image responsive */
    height: auto; /* Maintain the aspect ratio */
    transition: transform 0.3s ease; /* Smooth zoom effect */
}

.image-container:hover img {
    transform: scale(2.0); /* Scale up the image on hover */
}
